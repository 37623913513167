





<script>

/**
 * Agent Basic Info component
 */

//import Choices from "choices.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Choices from "choices.js";
import { required } from "vuelidate/lib/validators";
import { uploadFile } from '@/api/misc'


import {getBrokerageApi} from "@/api/brokerage"

import avatar_icon from '@/assets/images/users/avatar-1.jpg'
export default {



    props: {
        agent: {
            type: Object,
            default: () => ({
                fields: {
                    basic: {},
                    key_dates      : {
                        key_date_registration : '',
                        key_date_fee_start    : '',
                        contract_start_date   : '',
                        contract_end_date     : '',
                    }
                }
            })
        },

    },
    components: {
        flatPickr
    },


    data() {

        return {
            avatar: avatar_icon,
            office_choice : {},
            office_list : [],
            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "m/d/Y",
                altFormat: "m/d/Y",
                ariaDateFormat: "m/d/Y",
                allowInput : true
            },
        }

    },

    compute: {

    },

    validations: {


        agent: {
            first_name: { required },
            last_name: { required },
            code: { required },
            phone: { required },
            LWA_userName: { required },
        }


    },

    watch: {
        agent: function(old, newAgentValue) {
            
            if (newAgentValue)  {
                this.avatar = newAgentValue.picture
            }
        }
    },

    methods: {

        update_agent(agent) {
           
            this.office_list.map(e => {
                e.selected = e.value == agent.office_code? true :false
            })
            this.office_choice.setChoices(this.office_list)
        },

        chooseAvatar() {
            this.$refs.avatarFile.click()
        },

        avatarChanged(evt) {
            if (evt.target.files.length <= 0) {
                return
            }
            let formData = new FormData()
            formData.append("file", evt.target.files[0]);
            uploadFile(formData).then((res) => {
                if (res[0].errCode == 200) {
                    this.agent.avatar = res[0].response.url
                    this.avatar = res[0].response.url
                }
            })
        },

        reset() {
            this.$v.$reset();
        },

        validate() {
            this.$v.$touch();
            return this.$v.$invalid
        },

        onOfficeChanged(e) {
            let office = this.office_list.find((o) => o.value == e.detail.value)
            this.agent.office_name = office.label
        }
    },

    created() {

        getBrokerageApi().officeApi.list().then( (res) => {
            this.office_list = []
            res.data.map((f)=>{     
                // let select = this.staff.offices.find((e) => e == f.id) ? true : false
                this.office_list.push({
                    label : f.branch_name,
                    value : f.office_code,
                    office : f,
                    selected : f.office_code == this.agent.office_code ? true : false
                })
            })
         
            this.office_choice.setChoices(this.office_list)
        })

    },

    mounted() {

      
        this.office_choice = new Choices("#choices-office-input", {
            removeItemButton: true,
        });

        this.agent_type_choice = new Choices("#choices-agent-type")

    }

};
</script>


<template>
    <div class="row">

        <div class="card shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">Basic Info</h5>
                </div>
            </div>
            <div class="card-body">
                <div>
                    <div class="row">
                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" >First Name</label>
                                <input type="text" class="form-control" v-model="agent.first_name" 
                                    placeholder="Enter Name" :class="{
                                        'is-invalid': $v.agent.first_name.$error,
                                    }" />
                                <div v-if="$v.agent.first_name.$error" class="invalid-feedback">
                                    <span v-if="!$v.agent.first_name.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" >Last Name</label>
                                <input type="text" class="form-control" 
                                    placeholder="Enter Last Name" v-model="agent.last_name" :class="{
                                        'is-invalid': $v.agent.last_name.$error,
                                    }" />
                                <div v-if="$v.agent.last_name.$error" class="invalid-feedback">
                                    <span v-if="!$v.agent.last_name.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" >Legal Given Name</label>
                                <input type="text" class="form-control" 
                                    placeholder="Enter Last Name" v-model="agent.fields.basic.given_name"  />
                               
                            </div>
                        </div>

                        
                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-designation-input">Agent Type</label>
                                <select class="form-control" v-model="agent.a_status"  name="choices-agent-type" id="choices-agent-type">
                                    <option value="Agent" >Independent Agent</option>
                                    <option value="Licensed Assistant" >Licensed Assistant</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">Board License V#</label>
                                <input type="text" class="form-control" v-model="agent.code" 
                                    placeholder="Enter Agent VIN" :class="{
                                        'is-invalid': $v.agent.code.$error,
                                    }" :disabled="agent.id?true: false" :readonly="agent.id?true: false" />
                                <div v-if="$v.agent.code.$error" class="invalid-feedback">
                                    <span v-if="!$v.agent.code.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">Agent Code</label>
                                <input type="text" class="form-control" v-model="agent.LWA_userName" 
                                    placeholder="Enter Code" :class="{
                                        'is-invalid': $v.agent.LWA_userName.$error,
                                    }" :disabled="agent.id?true: false" :readonly="agent.id?true: false" />
                                <div v-if="$v.agent.LWA_userName.$error" class="invalid-feedback">
                                    <span v-if="!$v.agent.LWA_userName.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">PM Code</label>
                                <input type="text" class="form-control" v-model="agent.property_mgt_code"  placeholder="Enter PM Code" />
                               
                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label for="choices-office-input" class="form-label">Office</label>
                                <select class="form-control" v-model="agent.office_code" @change="onOfficeChanged" name="choices-office-input" id="choices-office-input">
                                </select>
                            </div>
                        </div>

                    </div>

                  


                    <div class="row">

                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">Register Date</label>
                                <flat-pickr :config="dpconfig" v-model="agent.fields.key_date.key_date_registration" placeholder="Select a date" :disabled="agent.id?true: false" :readonly="agent.id?true: false"
                                class="form-control"></flat-pickr>
                               
                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">Fee Run Date</label>
                                <flat-pickr :config="dpconfig" v-model="agent.fields.key_date.key_date_fee_start" placeholder="Select a date"  :disabled="agent.id?true: false"
                                class="form-control"></flat-pickr>
                               
                            </div>
                        </div>
                      
                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">Contract Start Date</label>
                                <flat-pickr :config="dpconfig" v-model="agent.fields.key_date.contract_start_date" placeholder="Select a date" 
                                class="form-control"></flat-pickr>
                               
                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">Contract End Date</label>
                                <flat-pickr :config="dpconfig" v-model="agent.fields.key_date.contract_end_date" placeholder="Select a date"  
                                class="form-control"></flat-pickr>
                               
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!-- end card -->

        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            02
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">Personel Info</h5>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="mb-3">
                            <label for="contact-info-phone-input">Phone :</label>
                            <input type="text" class="form-control" v-model="agent.phone" id="contact-info-phone-input" v-mask="'###-###-####'"
                                placeholder="Enter phone" :class="{
                                    'is-invalid': $v.agent.phone.$error,
                                }" />
                            <div v-if="$v.agent.phone.$error" class="invalid-feedback">
                                <span v-if="!$v.agent.phone.required">This value is required.</span>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4">
                        <div class="mb-3">
                            <label for="contact-info-email-input">Email :</label>
                            <input type="email" class="form-control" v-model="agent.email" 
                                placeholder="Enter Email" />
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="mb-md-0">
                            <label for="contact-info-birthday-input" class="form-label">Birthday</label>
                            <flat-pickr :config="dpconfig" v-model="agent.birthday" placeholder="Select a date"
                                class="form-control"></flat-pickr>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <div class="mb-md-0">
                            <label for="contact-info-birthday-input" class="form-label">Unit</label>
                            <input type="text" class="form-control" v-model="agent.fields.basic.unit" placeholder="Enter unit" />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mb-md-0">
                            <label  class="form-label">Street Address</label>
                            <input type="text" class="form-control" v-model="agent.fields.basic.street" placeholder="" />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mb-md-0">
                            <label  class="form-label">City</label>
                            <input type="text" class="form-control" v-model="agent.fields.basic.city" placeholder="" />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mb-md-0">
                            <label  class="form-label">Province</label>
                            <input type="text" class="form-control" v-model="agent.fields.basic.province" placeholder=""  maxlength="2"/>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mb-md-0">
                            <label  class="form-label">Postal Code</label>
                            <input type="text" class="form-control" v-model="agent.fields.basic.postal_code" placeholder="" maxlength="7" v-mask="'NNN NNN'" />
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <!-- end card -->


        <!-- License-->
        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            03
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">License Info</h5>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="contact-info-phone-input">BCFSA License #:</label>
                            <input type="text" class="form-control" v-model="agent.fields.basic.bcfsa"  />
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="contact-info-phone-input">BCFSA Start Date:</label>
                            <flat-pickr :config="dpconfig" v-model="agent.fields.basic.bcfsa_start_date" placeholder="Select a date" class="form-control"></flat-pickr>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="mb-md-0">
                            <label for="contact-info-phone-input">BCFSA Expiry Date:</label>
                            <flat-pickr :config="dpconfig" v-model="agent.fields.basic.bcfsa_end_date" placeholder="Select a date" class="form-control"></flat-pickr>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="contact-info-birthday-input" class="form-label">Board License V#</label>
                            <input type="text" class="form-control" v-model="agent.code" placeholder="Enter Board License" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="contact-info-phone-input">RE/MAX ID #:</label>
                            <input type="text" class="form-control" v-model="agent.fields.basic.remax"  />
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="contact-info-phone-input">RE/MAX Start Date:</label>
                            <flat-pickr :config="dpconfig" v-model="agent.fields.basic.remax_start_date" placeholder="Select a date" class="form-control"></flat-pickr>
                        </div>
                    </div>
                  
                </div>
            </div>

        </div>
        <!-- end card -->



         <!-- Ogher-->
         <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            04
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">Other Info</h5>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="contact-info-phone-input">Copier #:</label>
                            <input type="text" class="form-control" v-model="agent.fields.basic.copier"  />
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="contact-info-phone-input">FOB #:</label>
                            <input type="text" class="form-control" v-model="agent.fields.basic.fob"  />
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="contact-info-phone-input"> Computer Username:</label>
                            <input type="text" class="form-control" v-model="agent.fields.basic.computer_login_name"  />
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="contact-info-phone-input">Login Password:</label>
                            <input type="text" class="form-control" v-model="agent.fields.basic.computer_password"  />
                        </div>
                    </div>
                   
                </div>
               
            </div>

        </div>
        <!-- end card -->

    </div>
</template>